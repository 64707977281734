import React from "react"
import { Alert } from "reactstrap"

import { getPrice } from "@simplur/gatsby-theme-full-site/src/utils/product"
import { useShipping } from "@simplur/gatsby-theme-full-site/src/hooks"

const FreeShipping = ({ product }) => {
  // const data = useThemeSettings()
  const {
    calculateFixedShippingCharge,
    eligibleForFreeShipping,
  } = useShipping()

  const fixedShippingCharge = calculateFixedShippingCharge()
  const freeShipping =
    fixedShippingCharge === 0 &&
    eligibleForFreeShipping(getPrice(product.price))
  // const freeShipping = data.wpgraphql.themeGeneralSettings.ACFShippingSiteWide
  // const free = freeShipping.amountForFreeShipping <= getPrice(product.price)

  return freeShipping ? (
    ""
  ) : (
    ""
  )
}

export default FreeShipping
