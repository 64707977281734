import React from "react";

// function getId(url) {
//   if (url) {
//     const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
//     const match = url.match(regExp);

//     return match && match[2].length === 11 ? match[2] : null;
//   }
// }

const YoutubeVideo = ({ product }) => {
  // const videoId = product?.ACFProductVideo?.productVideo
  //   ? getId(product?.ACFProductVideo?.productVideo)
  //   : null;

  const videoUrl = product?.ACFProductVideo?.productVideo;

  return (
    <div className="player-wrapper my-3">
      {/* <iframe width="550" height="250" src={`//www.youtube.com/embed/${videoId}`} title={ product.id } frameBorder="0" allowFullScreen></iframe> */}
      <iframe src={videoUrl} />
    </div>
  );
};

export default YoutubeVideo;
