import React from "react";
import { FormGroup } from "reactstrap";
import {
  SubmitButton,
  ChangeCounterButton,
  Input,
  ProductDetailContainer,
  WishlistContainer,
} from "./styles";
import ProductWishlist from "../../../gatsby-theme-gutenberg-custom-blocks/components/Product/ProductWishlist";

type Props = {
  qty: string | number;
  setQty: Function;
  disabled: boolean;
  product?: any;
};

const ProductQtyFormInner: React.FC<Props> = ({
  qty,
  setQty,
  disabled,
  product,
}) => {
  const changeQuantity = (num: number) => {
    setQty((pQty) => (pQty + num >= 1 ? pQty + num : 1));
  };

  return (
    <ProductDetailContainer>
      <FormGroup
        row
        style={{
          padding: " 0 0 0 1rem",
          marginBottom: "0",
          width: "fit-content",
          flexWrap: "nowrap",
        }}
      >
        <ChangeCounterButton
          hideRightBorder
          className="btn-cta"
          onClick={() => changeQuantity(-1)}
        >
          -
        </ChangeCounterButton>
        <Input
          type="text"
          name="qty"
          id="qty"
          className="col-sm-2"
          value={qty}
          onChange={(e) => setQty(e.target.value)}
          readOnly
        />
        <ChangeCounterButton
          hideLeftBorder
          className="btn-cta"
          onClick={() => changeQuantity(1)}
        >
          +
        </ChangeCounterButton>
        <SubmitButton
          className="btn-cta ml-2"
          type="submit"
          disabled={disabled}
        >
          Add to Cart
        </SubmitButton>
      </FormGroup>
      {/* <WishlistContainer
        style={{
          boxShadow: "none",
        }}
        className="product__card"
      >
        <ProductWishlist product={product} />
        <span style={{ paddingLeft: "15px" }}>Add to Wishlist</span>
      </WishlistContainer> */}
    </ProductDetailContainer>
  );
};

export default ProductQtyFormInner;
