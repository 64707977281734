import React from "react";
import { graphql } from "gatsby";
import { Container, Row, Col } from "reactstrap";

import Layout from "../../components/layout";
import ProductSEO from "@simplur/gatsby-theme-full-site/src/components/product-seo";
import ProductDetail from "../../components/ProductDetail/index.tsx";
import FixedVideo from "@simplur/gatsby-theme-full-site/src/components/FixedVideo";
import KeyFeatures from "../../../../components/ProductPage/KeyFeatures";
import ProductSpecification from "../../../../components/ProductPage/ProductSpecification";
import ProductAnchors from "../../../../components/ProductPage/ProductAnchors";
import HeroTitle from "../../../../components/HeroTitle/HeroTitle";
import Accessories from "../../../../components/ProductPage/Accessories";
import RelatedProject from "../../../../components/ProductPage/RelatedProject";
import ProductReviews from "@simplur/gatsby-theme-full-site/src/components/ProductReviews";

const Product = ({ pageContext: product }) => {
  return (
    <Layout>
      <ProductSEO title="Product" product={product} />
      <ProductDetail product={product} />
      <FixedVideo livePageQuery={product} />
      <Container className="pt-5 px-sm-4 product-content">
        <Row>
          <Col md="12" className="d-none d-lg-block">
            <ProductAnchors product={product} />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <KeyFeatures product={product} />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <ProductSpecification product={product} />
          </Col>
        </Row>
        {!!product.description && (
          <Row>
            <Col md="12">
              <div id="product-overview">
                <HeroTitle tag={"h2"} title={"Product Overview"} />
                <div
                  dangerouslySetInnerHTML={{ __html: product?.description }}
                />
              </div>
            </Col>
          </Row>
        )}
        {!!product.ACFProductAccessories?.productAccessories && (
          <Row>
            <Col md="12">
              <div id="product-accessories">
                <HeroTitle tag={"h2"} title={"Accessories"} />
                <Accessories product={product} />
              </div>
            </Col>
          </Row>
        )}
        {!!product?.ACFRelatedProject?.relatedProject && (
          <Row>
            <Col md="12">
              <RelatedProject product={product} />
            </Col>
          </Row>
        )}
        <Row>
          <Col md="12">
            <ProductReviews product={product} />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

// export const query = graphql`
//   query KeyFeaturesQuery($id: ID!) {
//     wpgraphql {
//       product(id: $id) {
//         ACFKeyFeatures {
//           keyFeatures {
//             feature
//           }
//           fieldGroupName
//         }
//         ACFProductSpecifications {
//           productSpecification {
//             specification
//             description
//           }
//         }
//       }
//     }
//   }
// `;

export default Product;
