import React from "react";
import { Table } from "reactstrap";
import HeroTitle from "../HeroTitle/HeroTitle";

const ProductSpecification = ({ product }) => {
  const isProductSpecification =
    product?.ACFProductSpecifications?.productSpecification;

  return (
    <div id="specification">
      {!!isProductSpecification && (
        <>
          <HeroTitle title={"Specifications"} />
          <Table striped bordered hover responsive>
            <thead style={{ backgroundColor: "maroon", color: "#fff" }}>
              <tr>
                <th>Specification</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {isProductSpecification?.map((specs, index) => (
                <tr key={index}>
                  <td>{specs.specification}</td>
                  <td>{specs.description}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </div>
  );
};

export default ProductSpecification;
