import React from "react"
import { ProductPrice } from "./styles"

type Props = {
  regularPrice: number | string;
  salePrice: number | string;
  price: number | string;
}

const ProductPriceUI: React.FC<Props> = ({ regularPrice, salePrice, price }) => {
  let innerElement = <React.Fragment></React.Fragment>;
  if (regularPrice && salePrice) {
    innerElement = (
      <h5 className="mb-3">
        <del>{regularPrice}</del>{" "}
        <ProductPrice>{salePrice}</ProductPrice>
      </h5>
    )
  } else if (salePrice || price) {
    innerElement = (
      <h5 className="mb-3">
        <ProductPrice>
          {salePrice || price}
        </ProductPrice>
      </h5>
    )
  }
  return innerElement;
}

export default ProductPriceUI;
