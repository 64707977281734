import React from "react";
import HeroTitle from "../HeroTitle/HeroTitle";
import { Row, Col, Card, CardBody, CardImg, CardTitle } from "reactstrap";
import { Link } from "gatsby";

const RelatedProject = ({ product }) => {
  return (
    <>
      <div id="related-project">
        <HeroTitle tag={"h2"} title={"Related Project"} />
        <Row>
          {product?.ACFRelatedProject?.relatedProject.map((project) => (
            project?.relatedProjectPost && (
            <>
              <Col
                sm={12}
                md={6}
                lg={6}
                className="d-flex h-auto mb-4"
                key={project?.relatedProjectPost?.id}
              >
                <Card className="h-100 d-flex flex-column shadow text-center zoom">
                  {
                    <Link
                      to={
                        `/` +
                        project?.relatedProjectPost?.slug
                      }
                    >
                      <CardImg
                        top
                        src={
                          project?.relatedProjectPost?.featuredImage &&
                          project?.relatedProjectPost?.featuredImage?.node
                            .sourceUrl
                        }
                        alt={
                          project?.relatedProjectPost?.featuredImage
                            ? project?.relatedProjectPost?.featuredImage?.node
                                .altText
                            : project?.relatedProjectPost?.title
                        }
                      />
                    </Link>
                  }
                  <CardBody className="d-flex flex-column-reverse">
                    {
                      <Link
                        to={
                          `/` +
                          project?.relatedProjectPost?.slug
                        }
                      >
                        <CardTitle
                          tag="h5"
                          style={{ color: "#7FA8AB", fontWeight: "500" }}
                        >
                          <strong>
                            <span
                              style={{ color: "#EF7777" }}
                              className="related_project_title_text"
                            >
                              Project:{" "}
                            </span>
                            {project?.relatedProjectPost?.title}
                          </strong>
                        </CardTitle>
                      </Link>
                    }
                  </CardBody>
                </Card>
              </Col>
            </>
            )
          ))}
        </Row>
      </div>
    </>
  )
};

export default RelatedProject;

