import React from "react";
import { Row, Col } from "reactstrap";
import ProductCard from "../../@simplur/gatsby-theme-full-site/components/product-card";

const Accessories = ({ product }) => {
  return (
    <>
      <Row>
        {product?.ACFProductAccessories?.productAccessories.map((prod) => (
          <>
            {!!prod.accessorySlug && (
              <Col
                md={4}
                lg={3}
                className="d-flex h-auto mb-4"
                key={prod.accessorySlug.id}
              >
                <ProductCard product={prod.accessorySlug} />
              </Col>
            )}
          </>
        ))}
      </Row>
    </>
  );
};

export default Accessories;
