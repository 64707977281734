import React from "react";
import { Container, Row, Col, CardDeck } from "reactstrap";
import MaybeProductCard from "../components/maybe-card";

const MustHaveCard = ({ product }) => {
  return (
    <Row className="no-gutters">
      {product?.ACFProductAccessories?.mustHaves.map((product) => (
        <>
          {!!product.mustHaveProduct && (
            <Col
              md={4}
              lg={4}
              className="pr-3 d-flex h-auto "
              key={product.mustHaveProduct.id}
            >
              <MaybeProductCard product={product.mustHaveProduct} />
            </Col>
          )}
        </>
      ))}
    </Row>
  );
};
export default MustHaveCard;
