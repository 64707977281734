import styled, { css } from "styled-components/macro";
import { Button, Input as RawInput } from "reactstrap"

export const ChangeCounterButton = styled(Button) <{ hideLeftBorder?: boolean; hideRightBorder?: boolean }>`
    background: white;
    color: black;
    font-size: 1rem;
    border-color: #ced4da;
    border-radius: 0;
    height: 38px;
    box-shadow: none;

    ${({ hideLeftBorder }) =>
    hideLeftBorder &&
    css`
      border-left: none;
    `}
    ${({ hideRightBorder }) =>
    hideRightBorder &&
    css`
      border-right: none;
    `}

    :hover, :active, :focus {
      background: #009dff;
      background-color:  #009dff;
      box-shadow: none;
    }
`;

export const SubmitButton = styled(Button)`
    border-radius: 8px;
    word-spacing: 2px;
    font-weight: 600;
    padding: 6px 16px;
    min-width: fit-content;
`;

export const Input = styled(RawInput)`
    background-color: white !important;
    text-align: center;
    width: 70px;
    height: 38px;

    :hover, :active, :focus {
      box-shadow: none;
      border-color: #ced4da;
      cursor: default;
    }
`;

export const ProductPrice = styled.span`
    color: #bf1f23;
    font-weight: bold;
`

export const ProductDetailContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const WishlistContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 145px;

  @media (max-width: 480px) {
    margin-top: 1rem;
  }
`;