import React from "react";
import { Link } from "gatsby";
import { Button, Badge } from "reactstrap";
import styled from "styled-components";

import {
  useAddToCart,
  useCart,
  useThemeOption,
} from "@simplur/gatsby-theme-full-site/src/hooks";
import CardModal from "@simplur/gatsby-theme-full-site/src/components/CardModal";
import ListSignup from "@simplur/gatsby-theme-full-site/src/components/ListSignup";
import { ShoppingCart } from "styled-icons/fa-solid/ShoppingCart";

const CartWhite = styled(ShoppingCart)`
  color: #fff;
  width: 1rem;
`;

const ProductCardFooter = ({ product }) => {
  const { addToCartHandler } = useAddToCart();
  const { isProductExist } = useCart();
  const { singleProductBaseSlug } = useThemeOption();

  const isProductAddedToCart = isProductExist(product);
  let canShowAddToCart =
    !isProductAddedToCart && product.stockStatus === "IN_STOCK";
  let hasAddonProducts = false;

  const validateAddonProduct = (product) => {
    let isValid = false;
    const forceSelectionAddons = product.ACFAddOnProduct.products?.filter(
      (p) => p.forceSelection && p.selectProducts
    );
    isValid = forceSelectionAddons.length === 0 ? true : false;
    return isValid;
  };

  if (product.ACFAddOnProduct?.products?.length && canShowAddToCart) {
    hasAddonProducts = true;
    canShowAddToCart = validateAddonProduct(product);
  }

  let cardFooter;
  if (product.type !== "VARIABLE") {
    const { freeGiftWithSignup, ecommerceAndShipping } = product.ACFEcommerce;

    if (freeGiftWithSignup === "freeGift") {
      cardFooter = <ListSignup productName={product.name} />;
    } else if (
      ecommerceAndShipping !== "ecommerce" ||
      (ecommerceAndShipping === "ecommerce" && !product.price)
    ) {
      cardFooter = <CardModal product={product} />;
    } else {
      cardFooter = (
        <div className="text-center mt-0">
          {/* {isProductAddedToCart && <div className="small">Added to Cart</div>} */}

          {canShowAddToCart ? (
            <Button
              color="cta"
              className="btn-sm small text-center btn-block"
              onClick={() => addToCartHandler(product, 1)}
            >
              Add to Cart
            </Button>
          ) : hasAddonProducts ? (
            <Link
              to={`/${singleProductBaseSlug}/${product.slug}`}
              className="btn btn-cta btn-block"
            >
              View Now
            </Link>
          ) : null}

          {!canShowAddToCart && product.stockStatus !== "IN_STOCK" && (
            <Link
              to={`/${singleProductBaseSlug}/${product.slug}`}
              className="btn btn-cta btn-block"
            >
              View Now
            </Link>
          )}

          {isProductAddedToCart && (
            <Link to="/cart" className="btn btn-cta text-center border-cta in-cart">
              In Cart! View{" "}
              <span>
                <CartWhite />
              </span>{" "}
            </Link>
          )}
        </div>
      );
    }
  } else {
    cardFooter = (
      <Link
        to={`/${singleProductBaseSlug}/${product.slug}`}
        className="btn btn-cta btn-block"
      >
        View Details
      </Link>
    );
  }

  return cardFooter;
};

export default ProductCardFooter;
