import React from "react";
import HeroTitle from "../HeroTitle/HeroTitle";

import "./KeyFeatures.scss";

const KeyFeatures = ({ product }) => {
  const isKeyFeatures = product.ACFKeyFeatures.keyFeatures;

  return (
    <>
      {!!isKeyFeatures && (
        <div id="key-features">
          <HeroTitle title={"Key Features"} />
          <ul class="card-columns">
            {isKeyFeatures?.map((features) => (
              <li className="my-3">{features.feature}</li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default KeyFeatures;
