import React from "react";
import { Container, Row, Col, CardGroup, CardDeck } from "reactstrap";

import { getPrice } from "@simplur/gatsby-theme-full-site/src/utils/product";
import { useThemeOption } from "@simplur/gatsby-theme-full-site/src/hooks";
import { ProductDetailContextProvider } from "@simplur/gatsby-theme-full-site/src/contexts/ProductDetailContext";

import ProductPrice from "@simplur/gatsby-theme-full-site/src/components/ProductDetail/ProductPrice.js";
import ProductImageGallery from "@simplur/gatsby-theme-full-site/src/components/ProductDetail/ProductImageGallery";
import "../../../../styles/_custom.scss";
import ProductSynchronyInfo from "@simplur/gatsby-theme-full-site/src/components/ProductDetail/ProductSynchronyInfo";
import BundleProduct from "@simplur/gatsby-theme-full-site/src/components/ProductDetail/BundleProduct";
import ProductVariation from "@simplur/gatsby-theme-full-site/src/components/ProductDetail/ProductVariation";
import AddOnProduct from "@simplur/gatsby-theme-full-site/src/components/ProductDetail/AddOnProduct";
import YoutubeVideo from "../../../../components/ProductPage/YoutubeVideo";
import { WishlistContainer } from "./styles";

import ProductWishlist from "../../../gatsby-theme-gutenberg-custom-blocks/components/Product/ProductWishlist";
import MustHaveProductCard from "../must-have-card";
import { FaLongArrowAltRight } from "react-icons/fa";
import { Link } from "gatsby";
import Discontinued from "@simplur/gatsby-theme-full-site/src/components/ProductDetail/Discontinued";

const ProductDetails = ({ product }) => {
  const { synchrony: { merchantId = "" } = {} } = useThemeOption();
  const inStore = product.ACFEcommerce.ecommerceAndShipping === "ecommerce";

  return (
    <Container className="pt-3 px-sm-4">
      <Row className="mb-3">
        <Col>    
          <div className="category-heading d-flex justify-content-between">
            <h1
              className="category__title d-inline"
              dangerouslySetInnerHTML={{ __html: product?.name }}
            />
          </div>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col lg="6">
          <ProductImageGallery product={product} />
        </Col>
        <Col lg="6">
            <Discontinued product={product} />
            <div className="justify-content-between" style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }} >
                { product?.sku ? (
                <div className="text-muted">
                    SKU: 
                    <span style={{ paddingLeft: "5px"}}
                    dangerouslySetInnerHTML={{ __html: product?.sku }}
                    />
                </div>
                ) : null }
                <WishlistContainer
                    style={{
                        boxShadow: "none",
                    }}
                    className="product__card"
                >
                    <ProductWishlist product={product} />
                    <span style={{ paddingLeft: "30px" }}>Add to Wishlist</span>
                </WishlistContainer>
            </div>

            <div
              dangerouslySetInnerHTML={{ __html: product?.shortDescription }}
            />
            <ProductDetailContextProvider product={product}>
              {product.type === "VARIABLE" ? (
                <ProductVariation variationProduct={product} />
              ) : null}

              {product.ACFAddOnProduct &&
              product.ACFAddOnProduct.products?.length ? (
                <AddOnProduct product={product} />
              ) : null}
              {product.type === "BUNDLE" ? (
                <BundleProduct product={product} />
              ) : null}
              {product?.ACFProductVideo?.productVideo && (
                <YoutubeVideo product={product} />
              )}
              {merchantId && inStore && getPrice(product.price) > 150 && (
                <ProductSynchronyInfo product={product} />
              )}
              <ProductPrice product={product} />
            </ProductDetailContextProvider>

            <div className="d-none d-lg-block">
              {product.ACFProductAccessories?.mustHaves ? (
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 20,
                      marginBottom: "10px",
                    }}
                  >
                    <h6 className="" style={{ fontSize: 14, fontWeight: "bold" }}>
                      Must-haves:
                    </h6>
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "15px",
                        cursor: "pointer",
                      }}
                    >
                      <h6 style={{ fontSize: 14 }}>See all</h6>
                      <FaLongArrowAltRight
                        style={{ color: "green", paddingBottom: 5 }}
                      />
                    </span>
                  </div>
                  <MustHaveProductCard product={product} />
                </div>
              ) : null}
            </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ProductDetails;
