import React from "react";
import { Link } from "react-scroll";
import "./ProductAnchors.scss";

const ProductAnchors = ({ product }) => {

  const totalLength = product?.ACFKeyFeatures?.keyFeatures?.length || 0
                    + product?.ACFProductSpecifications?.productSpecification?.length || 0
                    + product?.ACFProductAccessories?.productAccessories?.length || 0
                    + product?.ACFRelatedProject?.relatedProject?.length || 0;

  return (
    <div>
      { totalLength >= 1 &&
        <ul className={"product_anchor"}>
          {!!product.ACFKeyFeatures.keyFeatures && (
            <li className={"product_anchor__item d-sm-block"}>
              <Link to="key-features" smooth={true} duration={400}>
                Key Features
              </Link>
            </li>
          )}
          {!!product.ACFProductSpecifications.productSpecification && (
            <li className={"product_anchor__item"}>
              <Link to="specification" smooth={true} duration={400}>
                Specification
              </Link>
            </li>
          )}
          {!!product.description && (
            <li className={"product_anchor__item"}>
              <Link to="product-overview" smooth={true} duration={400}>
                Product Overview
              </Link>
            </li>
          )}
          {!!product?.ACFProductAccessories?.productAccessories && (
            <li className={"product_anchor__item"}>
              <Link to="product-accessories" smooth={true} duration={400}>
                Accessories
              </Link>
            </li>
          )}
          {!!product?.ACFRelatedProject?.relatedProject && (
            <li className={"product_anchor__item"}>
              <Link to="related-project" smooth={true} duration={500}>
                Related Projects
              </Link>
            </li>
          )}
        </ul>
      }
    </div>
  );
};

export default ProductAnchors;
