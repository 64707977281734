import React from "react";
import { Link } from "gatsby";
import {
Card,
CardBody,
CardImg,
CardTitle,
CardFooter,
Col,
} from "reactstrap";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import { useThemeOption } from "@simplur/gatsby-theme-full-site/src/hooks";
import ProductCardFooter from "../components/maybe-footer";
import ProductWishlist from "../../gatsby-theme-gutenberg-custom-blocks/components/Product/ProductWishlist";
import ProductRibbon from "@simplur/gatsby-theme-gutenberg-custom-blocks/src/components/Product/ProductRibbon";
const MaybeProductCard = ({ product }) => {
    const { productBaseSlug } = useThemeOption();
    let ribbonType = "";
    if (
        product.onSale &&
        product.dateOnSaleTo &&
        product.dateOnSaleFrom &&
        new Date() >= new Date(product.dateOnSaleFrom) &&
        new Date() <= new Date(product.dateOnSaleTo)
    ) {
    ribbonType = "SALE";
    } else if (
    product.date &&
    differenceInCalendarDays(new Date(), new Date(product.date)) <= 30
    ) {
    ribbonType = "NEW";
    }
return (
    <Card className="mb-4 flex-column shadow text-center product__card">
        <ProductWishlist product={product}  />
        {ribbonType ? <ProductRibbon type={ribbonType} /> : null}
        
        <div className="partial-border" >
            <Link to={`/${productBaseSlug}/` + product.slug}>
                <div className="card-image-wrapper" > 
                    <CardImg
                    top
                    className=""
                    src={product.image && product.image.sourceUrl}
                    srcSet={product.image && product.image.srcSet}
                    alt={product.image ? product.image.altText : product.title}
                    />
                </div>
            </Link>
        </div>
        <CardBody className="d-flex py-1 flex-column-reverse">
            <Link to={`/${productBaseSlug}/` + product.slug}>
                <CardTitle
                className="font-weight-bold line-clamp"
                style={{ fontSize: 12, lineHeight: "1rem" }}
                >
                {product.name}
                </CardTitle>
                {product.ACFEcommerce.ecommerceAndShipping === "ecommerce" && (
                <>
                <h5 className="" style={{ fontSize: 12 }} >
                {product.onSale ? <del>{product.regularPrice}</del> : null}
                <span className="font-weight-bold ml-3 text-price">
                {product.price}
                </span>
                </h5>
                </>
            )}
            </Link>
        </CardBody>
        <CardFooter className="pt-0" style={{ marginTop: -8 }}>
            <ProductCardFooter product={product} />
        </CardFooter>
        </Card>
);
};
export default MaybeProductCard;